import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { media, styled } from "../components/mediaCSS"
import { FaPizzaSlice } from "react-icons/fa"

import BackgroundImage from "gatsby-background-image"
import OrderButtons from "../components/orderButtons"

const SMSContainer = styled.div`
  background-color: #e51937;
  width: 75%;
  border-radius: 10px;
  margin: auto;
  min-height: 250px;
  color: #fff;
  font-size: 11px;

  h3 {
    background-color: #333;
    padding: 22px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    margin: 0px;
    font-size: 30px;
  }
  div {
    padding: 20px;
    font-size: 1.3rem;

    a {
      color: white;
    }
  }
`

const AppContainer = styled.div`
  width: 75%;
  border-radius: 10px;
  margin: auto;
  min-height: 250px;

  h3 {
    text-align: center;
    margin: auto;
    padding: 120px;
  }
`

const HomeContainer = styled.div`
  background-color: #555555;
  color: white;

  h2,
  h4 {
    text-shadow: 1px 1px 10px #000000, 1px 1px 10px #000000,
      1px 1px 10px #000000, 1px 1px 10px #000000, 1px 1px 10px #000000;
    margin: 0px;
    font-family: "Times New Roman", Times, serif;
  }
  h2 {
    font-weight: 900;
    font-size: 60px;
  }

  h3 {
    text-align: center;
  }

  h4 {
    font-size: 20px;
  }
  .headerImage {
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
    text-align: center;
    border-bottom: 3px solid #e51937;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }

  .homeBody {
    width: 100%;
    background-size: 100px;
    background-repeat: repeat;
  }

  .merchHighlights {
    min-height: 260px;
    background: #cdcdcd;
    background-size: cover;
    background-position: 50% 50%;
    margin: 15px 0px;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    h3 {
      position: absolute;
      font-size: 2.5rem;
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 8px;
      margin: 0px;
      top: 0px;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      box-sizing: border-box;
      font-family: Oswald;
    }

    .bottom {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.75);
      padding: 12px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      margin: 0px;
      position: absolute;
      bottom: 0px;
      text-align: center;
      width: 100%;
      box-sizing: border-box;
      font-size: 1.6rem;
    }
  }

  .homeHighlights {
    min-height: 260px;
    background: #cdcdcd;
    background-size: cover;
    background-position: 50% 50%;
    margin: 15px 0px;
    border: 3px solid #fff;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    h3 {
      position: absolute;
      font-size: 2.5rem;
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 8px;
      margin: 0px;
      top: 0px;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      box-sizing: border-box;
      font-family: Oswald;
    }

    .bottom {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.75);
      padding: 12px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      margin: 0px;
      position: absolute;
      bottom: 0px;
      text-align: center;
      width: 100%;
      box-sizing: border-box;
      font-size: 1.6rem;
    }
  }

  .announcement {
    background-color: #e51937;
    padding: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
  }

  .pizzaIcon {
    font-size: 50px;
  }

  .headerButtons {
    margin-top: 30px;
  }
`

const HighlightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  box-sizing: border-box;
  text-align: center;
  padding: 40px 0px;
  
  

  > * {
    box-sizing: border-box;
    margin-bottom: 20px;
    width: 90%;
    ${media.tablet_desktop`
   width: 30%;
    `}
`

const MidContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 50px 0px;

  > * {
    box-sizing: border-box;
    ${media.tablet_desktop`
   width: 40%;
    `}
  }
`

const IndexPage = props => {
  const {
    homeBeer,
    homeSalad,
    homeWings,
    homePizza,
    homeTaps,
    homeMerch,
    bodyBG,
    headerImage,
    allVenue,
    buynow,
    homeAppHand,
  } = props.data

  const venue = allVenue.nodes.find(
    v => v.ID_clients === Number(process.env.GATSBY_ID_clients)
  )

  return (
    <Layout isFullWidth={true}>
      <SEO title="Home" />
      <HomeContainer>
        <BackgroundImage
          Tag="section"
          className="headerImage"
          fluid={headerImage.childImageSharp.fluid}
          backgroundColor={`#2e1e21`}
        >
          <h4>East Coast Pizza</h4>
          {process.env.GATSBY_ID_clients === "56" && <h2>Mama’s Pizza</h2>}

          {process.env.GATSBY_ID_clients !== "56" && venue && (
            <h2>Mama’s Pizza - {venue.venueNameShort}</h2>
          )}

          <div className="headerButtons">
            <OrderButtons
              showPhone={false}
              ID_clients={process.env.GATSBY_ID_clients}
            ></OrderButtons>
          </div>
        </BackgroundImage>
        <BackgroundImage
          Tag="section"
          className="homeBody"
          fluid={bodyBG.childImageSharp.fluid}
          backgroundColor={`#2e1e21`}
        >
          <MidContainer>
            <SMSContainer>
              <h3>Join Mama&#8217;s Coupon Club and Text MAMAS to 21333</h3>
              <div>
                Mobile Coupon Club powered by Money Mailer. Message and data
                rates may apply. Contact your carrier for details. Max.
                autodialed alerts 4x/week. Consent not required for purchase for
                goods and services. TO UNSUBSCRIBE, TEXT 'STOP' TO 21333. FOR
                HELP, TEXT 'HELP' TO 21333. Other terms and conditions may
                apply. Void where prohibited. We will not share your information
                with any other person or entity. View our{" "}
                <a href="/privacy" title="Privacy Policy">
                  privacy policy
                </a>
                .
              </div>
            </SMSContainer>
            <AppContainer>
              <BackgroundImage
                Tag="article"
                className="merchHighlights"
                fluid={homeMerch.childImageSharp.fluid}
                backgroundColor={`#2e1e21`}
              >
                <a
                  key="merch"
                  rel="noopener noreferrer"
                  aria-label="Merchandise"
                  target="_blank"
                  href="//toromerch.printavo.com/merch/mama-s-pizza-merch"
                >
                  <h3>We Got Merch!!</h3>
                  <div>
                    <Img
                      style={{
                        height: "40px",
                        width: "140px",
                        margin: "60px auto",
                      }}
                      objectFit="contain"
                      fluid={buynow.childImageSharp.fluid}
                    />
                  </div>
                  <div className="bottom">
                    Visit our{" "}
                    <span style={{ textDecoration: "underline" }}>
                      online store
                    </span>{" "}
                    and show your love for Mama&#8217;s Pizza!
                  </div>
                </a>
              </BackgroundImage>
            </AppContainer>
          </MidContainer>

          {process.env.GATSBY_ID_clients === "56" && (
            <div className="announcement">
              <FaPizzaSlice className="pizzaIcon" />
              <span>New store in Houston (Spring) Now Open! 346-781-6262</span>
              <FaPizzaSlice className="pizzaIcon" />
            </div>
          )}

          <h3>Fort Worth/Tarrant County's “Best Pizza” OR “Pizza of Choice”</h3>
          <h3>
            Publications including the Fort Worth Star-Telegram and Fort Worth,
            Texas Magazine
          </h3>

          <HighlightContainer>
            <BackgroundImage
              Tag="article"
              className="homeHighlights"
              fluid={homeAppHand.childImageSharp.fluid}
              backgroundColor={`#2e1e21`}
            >
              <a
                key="app"
                rel="noopener noreferrer"
                aria-label="Mobile App"
                target="_blank"
                href="//onelink.to/ycx8h7"
              >
                <h3>Get the Mama's Pizza App!</h3>

                <div className="bottom">
                  Visit the{" "}
                  <span style={{ textDecoration: "underline" }}>App Store</span>{" "}
                  and download the Mama&#8217;s Pizza mobile app!
                </div>
              </a>
            </BackgroundImage>
            <BackgroundImage
              Tag="article"
              className="homeHighlights"
              fluid={homeBeer.childImageSharp.fluid}
              backgroundColor={`#2e1e21`}
            >
              <h3>Ice Cold Ones!</h3>
              <div className="bottom">
                We have some of the finest brews on tap just for you. Come on by
                and have a frosty beverage and a slice of east coast pizza.
              </div>
            </BackgroundImage>
            <BackgroundImage
              Tag="article"
              className="homeHighlights"
              fluid={homeSalad.childImageSharp.fluid}
              backgroundColor={`#2e1e21`}
            >
              <h3>Health Conscious</h3>
              <div className="bottom">
                Looking to have a fresh and light meal? Step over to our salad
                bar and get the greens you deserve!
              </div>
            </BackgroundImage>
            <BackgroundImage
              Tag="article"
              className="homeHighlights"
              fluid={homeWings.childImageSharp.fluid}
              backgroundColor={`#2e1e21`}
            >
              <h3>Wings! Wings! Wings!</h3>
              <div className="bottom">
                You want wings? We got you covered with all of our tempting
                flavors:
                <br />
                BBQ, Garlic Parmesan, Hot, and Mild
              </div>
            </BackgroundImage>
            <BackgroundImage
              Tag="article"
              className="homeHighlights"
              fluid={homePizza.childImageSharp.fluid}
              backgroundColor={`#2e1e21`}
            >
              <h3>Pizza At It&#8217;s Finest</h3>
              <div className="bottom">
                Mama’s special dough, sauces, fresh produce and 100% real
                cheese, coupled with our family dining atmosphere provide a
                unique pizza dining experience.
              </div>
            </BackgroundImage>
            <BackgroundImage
              Tag="article"
              className="homeHighlights"
              fluid={homeTaps.childImageSharp.fluid}
              backgroundColor={`#2e1e21`}
            >
              <h3>Cold Beverages</h3>
              <div className="bottom">
                Wash your food down with an assorted variety of ice cold
                beverages.
              </div>
            </BackgroundImage>
          </HighlightContainer>
        </BackgroundImage>
      </HomeContainer>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    allVenue {
      nodes {
        ID_clients
        address1
        address2
        venueName
        venueNameShort
        longitude
        latitude
        sitePath
        phone
        fullSiteUrl
        hours {
          CloseTime
          DayOfWeek
          OpenTime
        }
        externalServices {
          name
          url
        }
      }
    }

    downloadApp: file(relativePath: { eq: "downloadApp.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    homeAppHand: file(relativePath: { eq: "mamasapphand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    homeBeer: file(relativePath: { eq: "homeBeer.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    homeTaps: file(relativePath: { eq: "homeTaps.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    homeMerch: file(relativePath: { eq: "homeMerch.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    homeSalad: file(relativePath: { eq: "homeSalad.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    homePizza: file(relativePath: { eq: "homePizza.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    homeWings: file(relativePath: { eq: "homeWings.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bodyBG: file(relativePath: { eq: "dark-wood.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    buynow: file(relativePath: { eq: "buynow.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    headerImage: file(relativePath: { eq: "headerHome.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
